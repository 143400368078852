import React from 'react'
import { useTranslation } from 'react-i18next';

import ReceiptClockIcon from 'pages/assets/icons/ReceiptClockIcon'

function PendingPayment ({ order }) {

  const { t } = useTranslation()

  return (
    <section>
      <div className='app-main-container space-y-8'>
        <div className='flex flex-col items-center space-y-4 pt-20'>
          <ReceiptClockIcon />
          <p className='text-xl sm:text-2xl font-bold text-app-blue-2 text-center'>{t('successPaymentHeading')}</p>
        </div>
        <p className='text-xs sm:text-sm text-center sm:mx-16'>
          {t('successPaymentDisclaimer')}
        </p>
      </div>
    </section>
  )

}

export default PendingPayment