import React from 'react'
import { useTranslation } from 'react-i18next';
import Newsletter from 'components/layout/Newsletter'
import ImageGallery from 'react-image-gallery'

function About() {
  const { t } = useTranslation();

  const about = {
    description: (
      <>
        <p className='app-animation-translate-start text-justify'>
          {t('aboutUsParagraph1')}
        </p>
        <br />
        <img src='https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/3c41ccae8717a569051325dc93d339b753dbb5b683a6d160d6ca82eb319883a901d50d2cf2b60b90b8a1510c603fdd42ed647e73e638cf50b5d2f500f0a249f8eebd283924639d7e753f7d46972b32bcf2a6feab5ede008607358afa4afe2858a423631eb55402f6b01f5d9d42bcc38de5c729dca16ac4688b19/rez-file-1718758261300.jpeg'/>
        <br />
        <p className='app-animation-translate-start text-justify'>
          {t('aboutUsParagraph2')}
        </p>
        <br />
        <img src='https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/c2406d2ea975ce75c8c3f16f780866efb3910e9ab684a00f61d9a097abbc1a2195d5993f992b84c4607273c6290bca1179dc5b9033fd1240210e8ad00c60f08b989516c30928f7f469ea1ba0257e7f18f24a809a013639c87c837d318eb02e6cefcf169f18789e5d0570b5337b8573a7c3a1673d9684b4deb972/rez-file-1718758420709.jpeg' />
      </>
    ),
    our_role_img: [{
      original: 'https://storage.googleapis.com/nizerteklintasbumi/banner/Setup-Ecosystem_NLB.png',
      thumbnail: 'https://storage.googleapis.com/nizerteklintasbumi/banner/Setup-Ecosystem_NLB.png'
    }]
  }


  return (
    <div>
      <section>
        <div className='relative'>
          <img src="https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/776595305b4c4a7a76944886f7b32fbc099f687c427e901ad2d56735a4f477a75fba20f1c0fe806201ea15d684d3dcbfb2a535ab5346fdd52a79a45c812c347b3ecfc4f62954165ca4c5022a3a16f2621eba59d1d6119e4fbeffe24a1d783a710339fa18606c97dd26bf59e4afbd41dbbcba9dcc5689ef9f2d45/file-1719757860262.jpeg" alt="main" className='w-full max-h-40 sm:max-h-60 object-cover object-center' />
          <div className='absolute top-0 bottom-0 left-0 right-0 bg-black/60 text-white'>
            <div className='app-main-container flex flex-col justify-center items-center h-full gap-4 sm:gap-8'>
              <p className='text-lg sm:text-3xl max-w-md px-8 font-semibold sm:font-bold text-center app-animation-translate-start'>{t('aboutUsBannerText')}</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='app-main-container space-y-4'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('aboutUs')}
          </p>
          <div className='text-app-blue-3 text-sm sm:text-base max-w-2xl mx-auto'>{about.description}</div>
        </div>
      </section>

      {/* <section className='bg-app-grey'>
        <div className='app-main-container grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8 items-center'>
          <img className='app-animation-translate-right-start' src={visionMission} alt="vision and mission" />
          <div className='space-y-4 app-animation-translate-start'>
            <div>
              <p className='text-sm sm:text-base md:text-lg uppercase font-bold'>Vision</p>
              <p className='text-sm sm:text-base md:text-lg font-medium'>Lorem ipsum dolor sit amet lorem ipsum dolor sit amet</p>
            </div>
            <div>
              <p className='text-sm sm:text-base md:text-lg uppercase font-bold'>Mission</p>
              <p className='text-sm sm:text-base md:text-lg font-medium'>Lorem ipsum dolor sit amet lorem ipsum dolor sit amet</p>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section className='bg-app-grey'>
        <div className='app-main-container space-y-8'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('aboutUsTitle2')}
          </p>
          <div>
            <ImageGallery items={about.our_role_img} showThumbnails={false} showPlayButton={false} />
          </div>
        </div>
      </section> */}

      {/* <Newsletter /> */}
    </div>
  )
}

export default About