function ArrowRight ({ fill, width, height }) {

  return (
    <svg width={width || 9} height={height || 12} viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path 
        opacity="0.5" 
        d="M7.94579 4.56206L2.37768 0.664375C1.37615 -0.0366968 0 0.679803 0 1.90233V9.69769C0 10.9202 1.37614 11.6367 2.37768 10.9356L7.94579 7.03796C8.80519 6.43638 8.80519 5.16363 7.94579 4.56206Z" 
        fill={fill || '#5669FF'}
      />
    </svg>
  )

}

export default ArrowRight