import React from 'react'
import { useTranslation } from 'react-i18next';

import TicketList from '../components/TicketList';

function Tickets() {

  const { t } = useTranslation()

  return (
    <div>
      <section>
        <div className='app-main-container space-y-4'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('ticketPrice')}
          </p>
          <p className='text-xl sm:text-2xl font-bold text-center app-animation-translate-start'>
            {t('selectTicket')}
          </p>
        </div>
      </section>
      <section>
        <div className='app-main-container space-y-4'>
          <TicketList />
        </div>
      </section>
    </div>
  )

}

export default Tickets