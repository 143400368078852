import React from 'react'
import { useTranslation } from 'react-i18next';

import ReceiptClockIcon from 'pages/assets/icons/ReceiptClockIcon'

function PendingPayment ({ timer, handleCheckPayment, order }) {

  const { t } = useTranslation()

  return (
    <section>
      <div className='app-main-container space-y-8'>
        <div className='flex flex-col items-center space-y-4 pt-20'>
          <ReceiptClockIcon />
          <p className='text-xl sm:text-2xl font-bold text-app-blue-2 text-center'>{t('pendingPaymentHeading')}</p>
        </div>
        <div className='bg-app-blue-light p-4 rounded-xl flex flex-col items-center'>
          <span className='font-bold text-sm sm:text-base'>{t('paymentExpiredDateHeading')}:</span>
          <p><span className='font-bold'>{timer?.hours}</span> {t('hour')} : <span className='font-bold'>{timer?.minutes}</span> {t('minute')} : <span className='font-bold'>{timer?.seconds}</span> {t('second')}</p>
        </div>
        <p className='text-xs sm:text-sm text-center sm:mx-16'>
          {t('pendingPaymentDisclaimer')}
        </p>

        <div className='flex flex-col items-center w-full space-y-2'>
          <button
            onClick={handleCheckPayment} 
            className={
              `
              bg-app-blue
              px-5
              py-2 
              text-white 
              rounded-md 
              text-sm 
              md:text-base
            `
            }
          disabled={!order?.mpayment_link}
          >
            {t('checkPaymentButton')}
          </button>
        </div>
      </div>
    </section>
  )

}

export default PendingPayment