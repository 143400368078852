import React from 'react'
import { useTranslation } from 'react-i18next';

function Newsletter() {
    const { t } = useTranslation();

    return (
        <section className='border-t border-black'>
            <div className='app-main-container flex flex-col sm:flex-row gap-6 sm:py-12'>
                <div className='flex-1'>
                    <p className='text-base sm:text-2xl font-bold app-animation-translate-start'>
                        {t('newsLetterHeadlineText1')} <br />{t('newsLetterHeadlineText2')}
                    </p>
                </div>
                <div className='flex-1 space-y-2 app-animation-scale-start'>
                    <label className='text-xs sm:text-base font-semibold uppercase'>
                        {t('newsLetterSubscribeNow')}
                    </label>
                    <div className='flex gap-2'>
                        <input className='flex-1 border border-app-light-grey px-3 sm:px-4 py-2 rounded-md text-xs sm:text-sm' placeholder={t('newsLetterPlaceholder')} />
                        <button className='text-xs uppercase sm:text-sm bg-black px-3 sm:px-4 py-2 text-white rounded-md'>{t('newsLetterSubmitButton')}</button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Newsletter