module.exports = (keys, formValues, qty) => {
  let newFormValues = {}
  for (let qtyIndex = 0; qtyIndex < qty; qtyIndex ++) {
    keys.forEach((key) => {
      if (formValues[`${key}-${qtyIndex}`] != '' && formValues[`${key}-${qtyIndex}`]) {
        newFormValues[`${key}-${qtyIndex}`] = formValues[`${key}-${qtyIndex}`]
      } else {
        newFormValues[`${key}-${qtyIndex}`] = ''
      }
    })
  }
  return newFormValues
}