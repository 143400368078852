import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';

function FormValidation ({ value, showError }) {

	const { t } = useTranslation()

	const invalid = 
    value == '' && 
    showError
	
	return (
		<div>
			{
				invalid && (
					<div className='mt-2 text-xs text-red-500'>
						{t('incompleteFormValidation')}
					</div>
				)
			}
		</div>
	)

}

export default FormValidation