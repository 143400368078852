import React from 'react'
import { useTranslation } from 'react-i18next';
import formatCurrency from '../helpers/formatCurrency';



function PriceDetails({ priceDetails }) {

	const { t } = useTranslation()

	return (
		<div className='bg-app-grey mb-4 p-6 rounded-md'>
			{
				priceDetails && (
					<>
						<div className='text-sm md:text-base mb-2 flex justify-between'>
							<span className='font-bold'>{t('productPrice')}:</span>{formatCurrency(priceDetails.productPrice)}
						</div>
						<div className='text-sm md:text-base mb-2 flex justify-between'>
							<span className='font-bold'>{t('productTax')}:</span>{formatCurrency(priceDetails.pTaxPrice)}
						</div>
						<div className='text-sm md:text-base mb-2 flex justify-between'>
							<span className='font-bold'>{t('transactionFee')}:</span>{formatCurrency(priceDetails.mFeePrice)}
						</div>
						<div className='text-sm md:text-base mb-8 flex justify-between'>
							<span className='font-bold'>{t('transactionTax')}:</span>{formatCurrency(priceDetails.mTaxPrice)}
						</div>
						{
							priceDetails && priceDetails.allTicketDiscountValue && priceDetails.allTicketDiscountValue > 0 ?
								(
									<div className='text-sm md:text-base mb-8 flex justify-between'>
										<span className='font-bold'>{t('referralDiscount')}: </span>-{formatCurrency(priceDetails.allTicketDiscountValue)}
									</div>
								)
								:
								<></>
						}
						<div className='text-sm md:text-base flex justify-between'>
							<span className='font-bold'>{t('totalProductPrice')}:</span>{formatCurrency(priceDetails.transactionAmount)}
						</div>
					</>
				)
			}
		</div>
	)

}

export default PriceDetails