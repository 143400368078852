module.exports = (formValues, requiredFields) => {
  let results = []
  if (Object.keys(formValues).length == 0) {
    results.push(false)
  }
  for (let key in formValues) {
    requiredFields.forEach((field) => {
      if (key.includes(field)) {
        if (formValues[key] == '' || null) {
          results.push(false)
        } else {
          results.push(true)
        }
      }
    })
  }
  if (results.indexOf(false) > -1) {
    return false
  } else {
    return true
  }
}