import React from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next';

import logo from 'assets/images/logo.png'
import TwitterIcon from 'assets/icons/social-media/TwitterIcon'
import FacebookIcon from 'assets/icons/social-media/FacebookIcon'
import InstagramIcon from 'assets/icons/social-media/InstagramIcon'
import LinkedinIcon from 'assets/icons/social-media/LinkedinIcon'
import YoutubeIcon from 'assets/icons/social-media/YoutubeIcon'

function Footer({
  isEnglish,
  handleSwitchLanguage
}) {
  const { t } = useTranslation();

  return (
    <div className='bg-app-blue text-white'>
      <div className='app-nav-container flex flex-col sm:flex-row justify-between gap-6 sm:gap-0'>
        <div className='grid grid-cols-1 sm:grid-cols-3 gap-6 sm:gap-12 text-sm sm:text-base'>
          <ul className='space-y-2'>
            <li>
              <a href="/about">
                {t('aboutUs')}
              </a>
            </li>
            <li>
              <a href="/sponsors">
                {t('ourSponsors')}
              </a>
            </li>
            <li>
              <a href="/prices">
                {t('ticketPrice')}
              </a>
            </li>
          </ul>
          <ul className='space-y-2'>
            <li>
              <a href="/faq">
                {t('faq')}
              </a>
            </li>
            <li>
              <a href="/contact-us">
                {t('contactUs')}
              </a>
            </li>
          </ul>
        </div>
        <div className='space-y-2 sm:space-y-4'>
          <p className='font-normal uppercase text-base sm:text-lg'>{t('followUs')}</p>
          <div className='flex gap-2 sm:gap-4'>
            <a href='https://x.com/bakhtera_corp' target='_blank'>
              <TwitterIcon className="fill-white h-4 sm:h-6 w-4 sm:w-6" />
            </a>
            <a href='https://www.facebook.com/profile.php?id=100069576246376' target='_blank'>
              <FacebookIcon className="fill-white h-4 sm:h-6 w-4 sm:w-6" />
            </a>
            <a href='https://www.instagram.com/bigeventasia.id/' target='_blank'>
              <InstagramIcon className="fill-white h-4 sm:h-6 w-4 sm:w-6" />
            </a>
            <a href='https://www.linkedin.com/company/bakhtera-inovasi-gemilang-pt/' target='_blank'>
              <LinkedinIcon className="fill-white h-4 sm:h-6 w-4 sm:w-6" />
            </a>
          </div>
        </div>
      </div>
      <div className='app-nav-container flex flex-col sm:flex-row justify-end items-center sm:items-end gap-8'>
        <div>
          <label className=" relative inline-flex items-center cursor-pointer">
            <span className="me-3 text-xs md:text-sm font-medium text-white-900 dark:text-white-300">ID</span>
            <input
              type="checkbox"
              value={isEnglish}
              checked={isEnglish}
              onChange={() => handleSwitchLanguage()}
              className="sr-only peer"
            />
            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-red-300 dark:peer-focus:ring-red-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[26px] sm:after:start-[26px] md:after:start-[28px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-red-600"></div>
            <span className="ms-3 text-xs md:text-sm font-medium text-white-900 dark:text-white-300">EN</span>
          </label>
        </div>
        <div className='text-[10px] sm:text-sm'>
          <p><a href="/privacy-policy">{t('privacyPolicy')}</a></p>
        </div>
        <div className='text-[10px] sm:text-sm'>
          <p><a href='https://cloudxier.com' target='_blank'>{moment().year()} {t('copyrightText')}</a></p>
        </div>
      </div>
    </div>
  )
}

export default Footer