import React from 'react'
import { useTranslation } from 'react-i18next';

import {
  sponsoredByLogo,
  supportedByLogo,
  ticketingByLogo,
  djPerformByLogo,
  mediaByLogo,
  venueByLogo
} from 'utils/constants/data';

function OurSponsors({
  landingPage
}) {

  const { t } = useTranslation()

  const sponsors = {
    sponsoredBy: (
      <div className="grid grid-cols-4 gap-4 items-center place-items-center app-animation-translate-start">
        {
          sponsoredByLogo && sponsoredByLogo.map((logo) => {
            return <img src={logo && logo.image_url} className="h-full w-full max-w-[150px] sm:max-w-[300px] object-contain" alt="client-logo" />
          })
        }
        {/* <p><b>{t('manyMore')}</b></p> */}
      </div>
    ),
    ticketingSytem: (
      <div className="grid grid-cols-1 gap-4 items-center place-items-center app-animation-translate-start">
        {
          ticketingByLogo && ticketingByLogo.map((logo) => {
            return <img src={logo && logo.image_url} className="h-full w-full max-w-[150px] sm:max-w-[300px] object-contain" alt="client-logo" />
          })
        }
        {/* <p><b>{t('manyMore')}</b></p> */}
      </div>
    ),
    djPerformance: (
      <div className="grid grid-cols-1 gap-4 items-center place-items-center app-animation-translate-start">
        {
          djPerformByLogo && djPerformByLogo.map((logo) => {
            return <img src={logo && logo.image_url} className="h-full w-full max-w-[150px] sm:max-w-[300px] object-contain" alt="client-logo" />
          })
        }
        {/* <p><b>{t('manyMore')}</b></p> */}
      </div>
    ),
    venuePartner: (
      <div className="grid grid-cols-2 gap-4 items-center place-items-center app-animation-translate-start">
        {
          venueByLogo && venueByLogo.map((logo) => {
            return <img src={logo && logo.image_url} className="h-full w-full max-w-[150px] sm:max-w-[300px] object-contain" alt="client-logo" />
          })
        }
        {/* <p><b>{t('manyMore')}</b></p> */}
      </div>
    ),
    mediaPartner: (
      <div className="grid grid-cols-3 gap-4 items-center place-items-center app-animation-translate-start">
        {
          mediaByLogo && mediaByLogo.map((logo) => {
            return <img src={logo && logo.image_url} className="h-full w-full max-w-[150px] sm:max-w-[300px] object-contain" alt="client-logo" />
          })
        }
        {/* <p><b>{t('manyMore')}</b></p> */}
      </div>
    ),
    supportedBy: (
      <div className="grid grid-cols-4 gap-4 items-center place-items-center app-animation-translate-start">
        {
          supportedByLogo && supportedByLogo.map((logo) => {
            return <img src={logo && logo.image_url} className="h-full w-full max-w-[150px] sm:max-w-[300px] object-contain" alt="client-logo" />
          })
        }
        {/* <p><b>{t('manyMore')}</b></p> */}
      </div>
    ),
  }

  return (
    <div>
      {!landingPage && (
        <section>
          <div className='relative'>
            <img src="https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/776595305b4c4a7a76944886f7b32fbc099f687c427e901ad2d56735a4f477a75fba20f1c0fe806201ea15d684d3dcbfb2a535ab5346fdd52a79a45c812c347b3ecfc4f62954165ca4c5022a3a16f2621eba59d1d6119e4fbeffe24a1d783a710339fa18606c97dd26bf59e4afbd41dbbcba9dcc5689ef9f2d45/file-1719757860262.jpeg" alt="main" className='w-full max-h-40 sm:max-h-60 object-cover object-center' />
            <div className='absolute top-0 bottom-0 left-0 right-0 bg-black/60 text-white'>
              <div className='app-main-container flex flex-col justify-center items-center h-full gap-4 sm:gap-8'>
                <p className='text-lg sm:text-3xl max-w-md px-8 font-semibold sm:font-bold text-center app-animation-translate-start'>{t('ourSponsorsBanner')}</p>
              </div>
            </div>
          </div>
        </section>
      )}
      <section>
        <div className='app-main-container space-y-8'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('sponsoredBy')}
          </p>
          <div className='text-app-blue-3 text-sm sm:text-base max-w-2xl mx-auto'>{sponsors.sponsoredBy}</div>
        </div>
      </section>
      <section>
        <div className='app-main-container space-y-8'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('eTicketingSystem')}
          </p>
          <div className='text-app-blue-3 text-sm sm:text-base max-w-2xl mx-auto'>{sponsors.ticketingSytem}</div>
        </div>
      </section>
      <section>
        <div className='app-main-container space-y-8'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('djPerformance')}
          </p>
          <div className='text-app-blue-3 text-sm sm:text-base max-w-2xl mx-auto'>{sponsors.djPerformance}</div>
        </div>
      </section>
      <section>
        <div className='app-main-container space-y-8'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('venuePartner')}
          </p>
          <div className='text-app-blue-3 text-sm sm:text-base max-w-2xl mx-auto'>{sponsors.venuePartner}</div>
        </div>
      </section>
      <section>
        <div className='app-main-container space-y-8'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('mediaPartner')}
          </p>
          <div className='text-app-blue-3 text-sm sm:text-base max-w-2xl mx-auto'>{sponsors.mediaPartner}</div>
        </div>
      </section>
      <section>
        <div className='app-main-container space-y-8'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('supportedBy')}
          </p>
          <div className='text-app-blue-3 text-sm sm:text-base max-w-2xl mx-auto'>{sponsors.supportedBy}</div>
        </div>
      </section>
    </div>
  )

}

export default OurSponsors