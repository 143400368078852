import React, { useEffect, useState } from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import Menu from 'components/menu/Menu'
import LoadingOverlay from './LoadingOverlay'
// import SupportChat from 'components/support_chat/SupportChat'
import WhatsappChat from 'components/button/WhatsappButton'
import useAnimation from 'utils/hooks/useAnimation'
import CookieConsent from 'components/modal/cookie_consent/CookieConsent'
import Cookies from 'js-cookie'
import i18n from 'i18next'

export const LoadingContext = React.createContext(null);

function MainContainer({ children }) {
  const [isMenuOpen, setMenuOpen] = useState(false)
  const [isOpenCookieConsent, setOpenCookieConsent] = useState(true);

  const [isEnglish, setIsEnglish] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const selectedLanguage = localStorage.getItem("language")
    if (selectedLanguage === "en") {
      setIsEnglish(true)
      i18n.changeLanguage("en")
    }
    if (selectedLanguage === "id") {
      setIsEnglish(false)
      i18n.changeLanguage("id")
    }
  }, []);

  const handleSwitchLanguage = () => {
    setIsEnglish((prev) => !prev)
    if (isEnglish === true) {
      localStorage.setItem("language", "id")
      i18n.changeLanguage("id")
    }
    if (isEnglish === false) {
      localStorage.setItem("language", "en")
      i18n.changeLanguage("en")
    }
    window.scrollTo(0, 0);
    window.location.reload(true)
  }

  useAnimation()

  return (
    <div>
      <LoadingOverlay isLoading={isLoading} />
      <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
        <Navbar
          openMenu={() => setMenuOpen(true)}
        />
        <div>
          {children}
        </div>
        <Footer
          isEnglish={isEnglish}
          handleSwitchLanguage={handleSwitchLanguage}
        />
        <Menu
          isOpen={isMenuOpen}
          onClose={() => setMenuOpen(false)}
        />
        {/* <SupportChat /> */}
        <WhatsappChat />
        {isOpenCookieConsent && window.location.pathname !== "/privacy-policy" && Cookies.get('cookie_consent') !== "true" && (
          <CookieConsent setOpenCookieConsent={setOpenCookieConsent} />
        )}
      </LoadingContext.Provider>
    </div>
  )
}

export default MainContainer