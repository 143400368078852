import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import useFetch from 'utils/hooks/useFetch'
import { GET_ALL_TICKETS } from 'api'
import formatCurrency from '../helpers/formatCurrency';
import ArrowRight from 'pages/assets/icons/arrow-right';

function TicketList() {

  const { data: tickets, fetch: getAllTickets } = useFetch(GET_ALL_TICKETS)
  const { t } = useTranslation()

  useEffect(() => {
    getAllTickets()
  }, [])

  return (
    <div>
      {
        tickets && tickets.length > 0 && tickets.map((ticket) => {
          return (
            <div key={ticket && ticket.id} className='flex gap-4 md:gap-6 px-5 md:px-10 py-3 md:py-5 mb-3 md:mb-5 shadow-md rounded-xl border-slate-100 border-2'>
              <div className='w-28 h-20 md:w-60 md:h-48 bg-black rounded-xl overflow-hidden'>
                <img src={ticket && ticket.thumbnail_url} className='object-cover w-full h-full' />
              </div>
              <div className='w-full space-y-2'>
                <div>
                  <div className='text-app-blue-3 text-xs sm:text-sm'>
                    {/* {ticket && moment(ticket.date).format('DD MMMM YYYY')} */}
                    {
                      ticket &&
                        ticket?.title &&
                        ticket?.title?.toLowerCase()?.indexOf("scramble") !== -1 ? t('scramblePax') : t('singlePax')
                    }
                  </div>
                </div>
                <div>
                  <div className='font-bold text-md sm:text-2xl'>{ticket && ticket.title}</div>
                </div>
                {
                  ticket &&
                  ticket?.title &&
                  ticket?.title?.toLowerCase()?.indexOf("scramble") !== -1 && (
                    <div>
                      <div className='text-sm sm:text-lg'>{t('scrambleBundling')}</div>
                    </div>
                  )
                }
                <div>
                  <div className='text-sm sm:text-lg'>{formatCurrency(ticket && ticket.price)} / {t('pax')}</div>
                </div>
                {
                  ticket &&
                  ticket?.title &&
                  ticket?.title?.toLowerCase()?.indexOf("scramble") !== -1 && (
                    <div className='leading-5 text-xs sm:text-sm bg-emerald-200/60 p-2 border border-2 border-emerald-200 rounded-lg w-full'>
                      {t('scrambleNote')}
                      <br/><br />
                      {t('willBeSelectedRandomly')}
                    </div>
                  )
                }
                <div className='flex justify-end'>
                  <a href={`/price-details/${ticket && ticket.id}`}>
                    <div className='font-bold text-sm sm:text-base flex items-center gap-1 mt-5 md:mt-28 select-none'>
                      {t('viewDetails')}
                      <ArrowRight fill={'#59c265'} width={15} />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          )
        }) || (
          <div></div>
        )
      }
    </div>
  )

}

export default TicketList