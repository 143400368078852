import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next';

import RegistrationForm from './RegistrationForm';

import formatFormValues from '../helpers/formatFormValues';

function ParticipanList({ qty, formValues, setFormValues, isRegistrationFormValid, setIsRegistrationFormValid }) {

  const { t } = useTranslation()
  const participantListRef = useRef(null)

  const [openAccordion, setOpenAccordion] = useState(0)

  useEffect(() => {
    // Add new form values when qty increased
    if (qty && qty > 0) {
      let newFormValues = formatFormValues(
        [
          'customer-name', 
          'customer-email', 
          'phone-number',
          'customer-phcode',
          'company-name',
          'customer-division',
          'id-number',
          'referral-code',
          'notes'
        ],
        formValues,
        qty
      )
      setFormValues(newFormValues)
    }
  }, [qty])

  useEffect(() => {
    // Re-focus to form if there are invalid inputs
    if (!isRegistrationFormValid) {
      participantListRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
    }
  }, [isRegistrationFormValid])

  useEffect(() => {
    if (qty && qty > 0) {
      setOpenAccordion(qty - 1)
    }
  }, [qty])

  const handleFormInput = (e) => {
    setIsRegistrationFormValid(true)
    const value = e && e.target && e.target.value
    const name = e && e.target && e.target.name
    let newFormValues = Object.assign({}, formValues)
    newFormValues[name] = value
    setFormValues(newFormValues)
  }

  const renderParticipantList = () => {
    let component = []
    if (qty && parseInt(qty) > 0) {
      for (let i = 0; i < qty; i++) {
        component.push(
          <div className='mb-3'>
            <div className='flex shadow-md justify-between border-2 border-slate-200 rounded-md py-2 px-4'>
              <div className='text-base md:text-lg font-bold'>
                {t('participantPlaceholder')} {i + 1}
              </div>
              <button onClick={() => setOpenAccordion(i)} className='text-xs md:text-sm'>
                {t('viewDetails')}
              </button>
            </div>
            <div className={`border-2 border-slate-200 rounded-md p-5 bg-slate-100 ${i == openAccordion ? null : 'hidden'}`}>
              <RegistrationForm
                index={i}
                handleFormInput={handleFormInput}
                formValues={formValues}
                isRegistrationFormValid={isRegistrationFormValid} />
            </div>
          </div>
        )
      }
    }
    return component
  }

  return (
    <div ref={participantListRef}>
      {renderParticipantList()}
    </div>
  )

}

export default ParticipanList