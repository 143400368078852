module.exports = (formValues, qty) => {
  let participants = []
  for (let i = 0; i < qty; i ++) {
    let participantForm = {}
    for (let key in formValues) {
      if (key.includes(i)) {
        let newKey = key.split('-')
        newKey.pop()
        newKey = newKey.join('_')
        participantForm[newKey] = formValues[key]
      }
    }
    participants.push(participantForm)
  }
  return participants
}