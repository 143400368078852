import React, { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom'
import moment from 'moment';
import useFetch from 'utils/hooks/useFetch'
import Swal from 'sweetalert2'
import {
  GET_TICKET_BY_ID,
  GET_PRICE_CALCULATION,
  GET_ALL_PAYMENT_CHANNELS,
  CREATE_BOOKING,
  CHECK_TICKET_STOCK
} from 'api'

import DateIcon from 'pages/assets/icons/date';
import LocationIcon from 'pages/assets/icons/location';

import NumberInput from '../components/QtyInput';
import ParticipantList from '../components/ParticipantList';
import PaymentMethods from '../components/PaymentMethods';
import PriceDetails from '../components/PriceDetails';

import validateForm from '../helpers/validateForm';
import formatParticipants from '../helpers/formatParticipants';

import { LoadingContext } from 'components/layout/MainContainer';
import formatReferralCodes from '../helpers/formatReferralCodes';

function TicketDetails() {

  const { isLoading, setIsLoading } = useContext(LoadingContext)

  const { t } = useTranslation()
  const { id } = useParams()
  const { data: ticket, fetch: getTicket } = useFetch(GET_TICKET_BY_ID)
  const { data: ticketStock, fetch: checkTicketStock } = useFetch(CHECK_TICKET_STOCK)
  const { data: priceCalculation, error: priceCalculationError, fetch: getPriceCalculation } = useFetch(GET_PRICE_CALCULATION)
  const { data: paymentChannels, fetch: getAllPaymentChannels } = useFetch(GET_ALL_PAYMENT_CHANNELS)
  const { data: bookingResult, error: createBookingError, fetch: createBooking } = useFetch(CREATE_BOOKING)

  const [qty, setQty] = useState(0)
  const [selectedPaymentMethod, selectPaymentMethod] = useState(null)
  const [formValues, setFormValues] = useState({})
  const [isRegistrationFormValid, setIsRegistrationFormValid] = useState(true)
  const [isPrivacyPolicyChecked, setIsPrivacyPolicyChecked] = useState(false)

  useEffect(() => {
    getTicket({
      params: {
        id
      }
    })
    getAllPaymentChannels()
    checkTicketStock({
      data: {
        qty
      },
      params: {
        id
      }
    })
  }, [])

  // Hardcoded: fixed qty for "scramble" ticket
  useEffect(() => {
    if (ticket && ticket.id) {
      if (ticket && ticket.title && ticket.title.toLowerCase().includes('scramble')) {
        if (ticketStock && ticketStock.stock && parseInt(ticketStock.stock) >= 3) {
          setQty(3)
        } else {
          setQty(0)
        }
      }
    }
  }, [ticket, ticketStock])

  useEffect(() => {
    setIsPrivacyPolicyChecked(false)
  }, [qty, selectedPaymentMethod])

  useEffect(() => {
    if (
      selectedPaymentMethod &&
      qty &&
      qty > 0
    ) {
      // Get referral codes
      const referralCodes = formatReferralCodes(formValues)
      getPriceCalculation({
        data: {
          payment_channel_id: selectedPaymentMethod,
          qty,
          referral_codes: referralCodes
        },
        params: {
          id
        }
      })
    }
  }, [selectedPaymentMethod, qty])

  useEffect(() => {
    selectPaymentMethod(null)
  }, [formValues])

  useEffect(() => {
    let errResp = null
    if (priceCalculationError) {
      errResp =
        priceCalculationError &&
        priceCalculationError.data &&
        priceCalculationError.data.message
    }
    if (createBookingError) {
      errResp =
        createBookingError &&
        createBookingError.data &&
        createBookingError.data.message
    }
    if (errResp) {
      Swal.fire({
        title: 'Error',
        text: errResp,
        icon: 'error'
      })
        .then(() => {
          window.location.reload()
        })
    }
    if (bookingResult) {
      const mRedirectUrl = bookingResult.m_redirect_url
      window.location.replace(mRedirectUrl)
    }
  }, [createBookingError, bookingResult, priceCalculationError])

  const validateRegistrationForm = () => {
    const requiredFields = [
      'customer-name',
      'customer-email',
      'phone-number',
      'company-name',
      'customer-division',
      'customer-phcode'
    ]
    const result = validateForm(formValues, requiredFields)
    setIsRegistrationFormValid(result)
    return result
  }

  const handleSubmit = () => {
    if (validateRegistrationForm()) {
      setIsLoading(true)
      const participants = formatParticipants(formValues, qty)
      createBooking({
        data: {
          ticket_id: id,
          mpayment_channel_id: selectedPaymentMethod,
          participants
        }
      })
    }
  }

  return (
    <div>
      <section>
        <div className='relative'>
          <img src="https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/776595305b4c4a7a76944886f7b32fbc099f687c427e901ad2d56735a4f477a75fba20f1c0fe806201ea15d684d3dcbfb2a535ab5346fdd52a79a45c812c347b3ecfc4f62954165ca4c5022a3a16f2621eba59d1d6119e4fbeffe24a1d783a710339fa18606c97dd26bf59e4afbd41dbbcba9dcc5689ef9f2d45/file-1719757860262.jpeg" alt="main" className='w-full max-h-40 sm:max-h-60 object-cover object-center' />
          <div className='absolute top-0 bottom-0 left-0 right-0 bg-black/60 text-white'>
            <div className='app-main-container flex flex-col justify-center items-center h-full gap-4 sm:gap-8'>
              <p className='text-lg sm:text-3xl max-w-md px-8 font-semibold sm:font-bold text-center app-animation-translate-start'>{t('ticketDetails')}</p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className='app-main-container space-y-10 md:space-y-12'>
          <div className='text-xl md:text-3xl'>
            {ticket && ticket.title}
          </div>
          <div className='space-y-4 md:space-y-6'>
            <div className='flex gap-4'>
              <div>
                <DateIcon bgFill={'#59c265'} fill={'#59c265'} size={50} />
              </div>
              <div className='space-y-0.5'>
                <div className='text-base md:text-xl'>
                  {ticket && moment(ticket.date).format('DD MMMM YYYY')}
                </div>
                <div className='text-sm md:text-base font-light'>
                  {ticket && moment(ticket.date).format('dddd, hh:mma')}
                </div>
              </div>
            </div>
            <div className='flex gap-4'>
              <div>
                <LocationIcon bgFill={'#59c265'} fill={'#59c265'} size={50} />
              </div>
              <div className='space-y-0.5'>
                <div className='text-base md:text-xl'>
                  {ticket && ticket.address_title}
                </div>
                <div className='text-sm md:text-base font-light'>
                  {ticket && ticket.address_details}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className='font-bold text-base md:text-xl mb-4'>
              {t('ticketDescription')}
            </div>
            <div className='text-base md:text-xl custom-list' dangerouslySetInnerHTML={{ __html: ticket && ticket.description }}></div>
          </div>
          <div>
            <div className='font-bold text-base md:text-xl mb-4'>
              {t('qty')}
            </div>
            <NumberInput
              setQty={setQty}
              qty={qty}
              maxQty={ticketStock && ticketStock.stock && parseInt(ticketStock.stock)}
              disabled={
                ticket && ticket.title && ticket.title.toLowerCase().includes('scramble') ||
                ticketStock && ticketStock.stock && parseInt(ticketStock.stock) < 1
              }
            />
            {
              ticketStock && ticketStock.stock && parseInt(ticketStock.stock) > 0 ?
                <div className='pt-2 text-xs md:text-sm text-app-blue font-bold'>{ticketStock && ticketStock.stock || 0} {t('availableStock')}</div>
                :
                <div className='pt-2 text-xs md:text-sm text-amber-700 font-bold'>{t('unavailableStock')}</div>
            }
          </div>
          {
            qty && qty > 0 && (
              <div>
                <div className='font-bold text-base md:text-xl mb-4'>
                  {t('participants')}
                </div>
                <ParticipantList
                  formValues={formValues}
                  setFormValues={setFormValues}
                  qty={qty}
                  isRegistrationFormValid={isRegistrationFormValid}
                  setIsRegistrationFormValid={setIsRegistrationFormValid} />
              </div>
            ) || null
          }
          {
            qty && qty > 0 && (
              <div>
                <div className='font-bold text-base md:text-xl mb-4'>
                  {t('choosePaymentMethod')}
                </div>
                <div className='leading-5 mb-4 text-white text-xs sm:text-sm bg-red-500 p-2 border border-2 border-red-700 rounded-lg w-full'>
                  {t('qrisPaymentNote')}
                </div>
                <PaymentMethods
                  selectPaymentMethod={selectPaymentMethod}
                  selectedPaymentMethod={selectedPaymentMethod}
                  paymentMethods={paymentChannels}
                  priceDetails={priceCalculation}
                />
              </div>
            ) || null
          }
          {
            qty && qty > 0 && selectedPaymentMethod && priceCalculation && (
              <div>
                <div className='font-bold text-base md:text-xl mb-4'>
                  {t('totalPrice')}
                </div>
                {
                  ticket &&
                  ticket.title &&
                  ticket.title.toLowerCase().includes('scramble') && (
                    <div className='leading-5 text-xs sm:text-sm bg-emerald-200/60 p-2 border border-2 border-emerald-200 rounded-lg w-full mb-4'>
                      {t('scrambleNote2')}
                      <br /><br />
                      {t('willBeSelectedRandomly')}
                    </div>
                  )
                }
                <PriceDetails priceDetails={priceCalculation} />
                <div className='flex items-start'>
                  <input checked={isPrivacyPolicyChecked} type="checkbox" onChange={() => setIsPrivacyPolicyChecked(!isPrivacyPolicyChecked)} className='scale-125 mr-2 mt-1' />
                  <span className='text-xs sm:text-sm'>{t('privacyPolicyAgreement')}<a href="/privacy-policy" target='_blank'><u>{t('privacyPolicy')}</u></a>.</span>
                </div>
              </div>
            ) || null
          }
          {
            qty && qty > 0 && selectedPaymentMethod && priceCalculation && (
              <div className='flex justify-center md:justify-end'>
                <button onClick={handleSubmit} className='bg-app-blue text-white rounded-md px-12 py-2 text-sm sm:text-base disabled:opacity-50' disabled={isPrivacyPolicyChecked === false}>
                  {t('continuePayment')}
                </button>
              </div>
            ) || null
          }
        </div>
      </section>
    </div>
  )

}

export default TicketDetails