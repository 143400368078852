import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import logo from 'assets/images/nlb-logo-w.png';
import BurgerBarMenu from 'assets/icons/BurgerBarMenu.png';

function Navbar({
  openMenu
}) {
  const { t } = useTranslation();
  const dropdownRef = useRef(null)
  const navbarRef = useRef(null)

  const [dropdownOpen, setDropdownOpen] = useState(null)

  const navigation = [
    {
      id: 1,
      label: t('aboutUs'),
      href: "/about"
    },
    {
      id: 2,
      label: t('ourSponsors'),
      href: '/sponsors'
    },
    {
      id: 3,
      label: t('ticketPrice'),
      href: "/prices"
    },
    {
      id: 4,
      label: t('faq'),
      href: "/faq",
    },
    {
      id: 5,
      label: t('contactUs'),
      href: "/contact-us",
    },
  ]

  const handleMouseOver = useCallback((e) => {
    if (dropdownOpen && !dropdownRef?.current?.contains(e.target) && !navbarRef?.current?.contains(e.target)) {
      setDropdownOpen(null)
    }
  }, [dropdownOpen, setDropdownOpen])

  useEffect(() => {
    window.addEventListener('mouseover', handleMouseOver)
    return () => {
      window.removeEventListener('mouseover', handleMouseOver)
    }
  }, [handleMouseOver])

  return (
    <>
      <div className={`sticky top-0 shadow-md transition-all z-10 bg-app-blue`}>
        <div ref={navbarRef} className={`app-nav-container flex justify-between items-center gap-6`}>
          <div>
            <a href='/'>
              <img src={logo} alt="logo" className='h-16 md:h-30' />
            </a>
          </div>
          <div className={`hidden sm:flex md:space-x-12 text-white font-bold`}>
            <ul className='flex items-center space-x-4 sm:text-sm md:text-base'>
              {navigation.map((nav => (
                <li
                  key={nav?.id}
                  onMouseOver={() => setDropdownOpen(nav?.id)}
                >
                  <a className='hover:border-b pb-1' href={nav.href}>{nav.label}</a>
                </li>
              )))}
            </ul>
          </div>
          <button
            className='text-white text-sm sm:hidden active:border-b-2 active:border-white transition-all font-medium'
            onClick={openMenu}
          >
            <img src={BurgerBarMenu} alt="burger-bar-menu" className='h-8 fill-white' />
          </button>
        </div>
        <div
          ref={dropdownRef}
          className={`absolute left-0 right-0 z-30 shadow-md bg-app-blue-3 ${navigation?.[dropdownOpen - 1]?.dropdownNav?.length > 0 ? "block" : "hidden"} transition-all`}
        >
          <ul className='app-main-container py-4 lg:py-6 grid grid-cols-3 gap-4 text-white'>
            {navigation?.[dropdownOpen - 1]?.dropdownNav?.map(nav => (
              <li
                key={nav?.id}
              >
                <a className='hover:border-b transition-all duration-300' href={nav.href}>{nav.label}</a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  )
}

export default Navbar