import React, { useEffect } from 'react'

function PaymentMethods({ paymentMethods, selectPaymentMethod, selectedPaymentMethod, priceDetails }) {

	useEffect(() => {
		if (selectedPaymentMethod && selectedPaymentMethod == 2 && priceDetails && priceDetails.transactionAmount) {
			if (priceDetails.transactionAmount >= 10000000) {
				selectPaymentMethod(4)
			}
		}
	}, [selectedPaymentMethod, priceDetails])

	return (
		<div className='grid grid-cols-2 md:grid-cols-4 gap-2'>
			{
				paymentMethods &&
				paymentMethods.length > 0 &&
				paymentMethods.map((item) => {
					if (item && item.is_active) {
						return (
							<button
								onClick={() => selectPaymentMethod && selectPaymentMethod(item.id)}
								className={`
									shadow-md 
									border-2 
									border-slate-200 
									rounded-md 
									py-2 
									px-4 
									mb-2 
									text-sm
									md:text-base 
									flex 
									items-center
									text-left
									disabled:opacity-30
									${selectedPaymentMethod &&
										selectedPaymentMethod == item.id ?
										'bg-app-blue text-white'
										:
										null
									}
								`}
								disabled={
									item.name && item.name === "QRIS" && !priceDetails ? 
										false 
									:
										item.name && item.name === "QRIS" && priceDetails && priceDetails.transactionAmount && priceDetails.transactionAmount >= 10000000 ? true : false
								}
							>
								{item && item.name}
							</button>
						)
					}
				})
			}
		</div>
	)

}

export default PaymentMethods