module.exports = (formValues) => {
  if (formValues && Object.entries(formValues) && Object.entries(formValues).length > 0) {
    let referralCodes = []
    for (let key in formValues) {
      if (key.includes('referral-code')) {
        if (formValues[key] != '') {
          referralCodes.push(formValues[key])
        }
      }
    }
    return referralCodes
  }
}