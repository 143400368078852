import React from 'react'

function NumberInput({ setQty, qty, maxQty, disabled }) {

  const validateQtyKeypress = (e) => {
    if (e.code === 'Minus') {
      e.preventDefault();
    }
  }

  const handleChangeQty = ({ target: { value } }) => {
    if (value && value < maxQty) {
      setQty(value)
    } else {
      setQty(maxQty)
    }
  }

  const handleAddQty = () => {
    qty = qty && parseInt(qty)
    if (qty < maxQty)
      setQty(qty + 1)
  }

  const handleDecQty = () => {
    qty = qty && parseInt(qty)
    if (qty > 0) {
      setQty(qty - 1)
    }
  }

  return (
    <div>
      <div class={`flex border-2 border-slate-200 rounded-lg w-36 justify-evenly ${disabled && 'bg-slate-200'}`}>
        <button disabled={disabled} onClick={handleDecQty} class="w-8 flex justify-center">
          <span class="font-bold text-3xl select-none">-</span>
        </button>
        <input disabled={disabled} onKeyPress={validateQtyKeypress} onChange={handleChangeQty} value={qty} type="number" min="0" class="outline-none text-center w-10 block border-0 py-2 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6" placeholder="0" />
        <button disabled={disabled} onClick={handleAddQty} class="w-8 flex items-center justify-center">
          <span class="font-bold text-2xl select-none">+</span>
        </button>
      </div>
    </div>
  )

}

export default NumberInput