import React from 'react'

import Loader from '../../assets/icons/loader.svg'

function LoadingOverlay ({ isLoading }) {


  if (isLoading) {

    document.body.style.overflow = 'hidden'

    return (
      <div className='inset-0 z-20 opacity-90 bg-white fixed h-screen flex justify-center'>
        <img src={Loader} className='w-1/2' />
      </div>
    )

  } else {

    document.body.style.overflow = 'scroll'

    return <></>

  }

}

export default LoadingOverlay