import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { countryPhoneCodes } from 'utils/constants';

import FormValidation from './FormValidation';

function RegistrationForm({ index, formValues, handleFormInput, isRegistrationFormValid }) {

  const { t } = useTranslation()

  // remove duplicate codes
  const newCountryPhoneCodes = countryPhoneCodes.filter((value, index, self) =>
    index === self.findIndex((t) => (
      t.code === value.code
    ))
  )

  return (
    <div className='grid grid-cols-1 sm:grid-cols-6 gap-x-8 gap-y-4'>
      <div class="sm:col-span-3">
        <label class="block text-sm font-medium leading-6 text-gray-900">{t('contactFormLabel1')} *</label>
        <div class="mt-1">
          <input onChange={handleFormInput} value={formValues[`customer-name-${index}`]} placeholder={t('contactFormPlaceholder1')} type="text" name={`customer-name-${index}`} class="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
          <FormValidation value={formValues[`customer-name-${index}`]} showError={!isRegistrationFormValid} />
        </div>
      </div>
      <div class="sm:col-span-3">
        <label class="block text-sm font-medium leading-6 text-gray-900">{t('contactFormLabel2')} *</label>
        <div class="mt-1">
          <input onChange={handleFormInput} value={formValues[`customer-email-${index}`]} placeholder={t('contactFormPlaceholder2')} type="text" name={`customer-email-${index}`} class="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
          <FormValidation value={formValues[`customer-email-${index}`]} showError={!isRegistrationFormValid} />
        </div>
      </div>
      <div class="sm:col-span-3">
        <label class="block text-sm font-medium leading-6 text-gray-900">{t('contactFormLabel7')} *</label>
        <div class="bg-white flex rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 w-full mt-1 p-2">
          {/* <select onChange={handleFormInput} value={formValues[`customer-phcode-${index}`]} name={`customer-phcode-${index}`} className='w-fit px-2 font-bold text-sm select-none outline-none'>
            <option value='' selected></option>
            {newCountryPhoneCodes.sort((a, b) => a.code - b.code).map(((country) => (
              <option value={country.code}>{country.code}</option>
            )))}
          </select> */}
          <span>+</span>(<input type='number' onChange={handleFormInput} value={formValues[`customer-phcode-${index}`]} name={`customer-phcode-${index}`} className='w-8 font-bold text-sm select-none outline-none text-center' /><span>)</span>
          <input onChange={handleFormInput} value={formValues[`phone-number-${index}`]} name={`phone-number-${index}`} placeholder={t('contactFormPlaceholder7')} type="number" min="0" class="outline-none w-full block pl-2 border-0 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6" />
        </div>
        <FormValidation value={formValues[`phone-number-${index}`] && formValues[`customer-phcode-${index}`]} showError={!isRegistrationFormValid} />
      </div>
      <div class="sm:col-span-3">
        <label class="block text-sm font-medium leading-6 text-gray-900">{t('contactFormLabel3')} *</label>
        <div class="mt-1">
          <input onChange={handleFormInput} value={formValues[`company-name-${index}`]} placeholder={t('contactFormPlaceholder3')} type="text" name={`company-name-${index}`} class="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
          <FormValidation value={formValues[`company-name-${index}`]} showError={!isRegistrationFormValid} />
        </div>
      </div>
      <div class="sm:col-span-3">
        <label class="block text-sm font-medium leading-6 text-gray-900">{t('contactFormLabel4')} *</label>
        <div class="mt-1">
          <input onChange={handleFormInput} value={formValues[`customer-division-${index}`]} placeholder={t('contactFormPlaceholder4')} type="text" name={`customer-division-${index}`} class="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
          <FormValidation value={formValues[`customer-division-${index}`]} showError={!isRegistrationFormValid} />
        </div>
      </div>
      <div class="sm:col-span-3">
        <label class="block text-sm font-medium leading-6 text-gray-900">{t('contactFormLabel6')}</label>
        <div class="mt-1">
          <input onChange={handleFormInput} value={formValues[`id-number-${index}`]} placeholder={t('contactFormPlaceholder6')} type="number" name={`id-number-${index}`} class="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
        </div>
      </div>
      <div class="sm:col-span-3">
        <label class="block text-sm font-medium leading-6 text-gray-900">{t('contactFormLabel8')}</label>
        <div class="mt-1">
          <input onChange={handleFormInput} value={formValues[`referral-code-${index}`]} placeholder={t('contactFormPlaceholder8')} type="text" name={`referral-code-${index}`} class="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
        </div>
      </div>
      <div class="sm:col-span-3">
        <label class="block text-sm font-medium leading-6 text-gray-900">{t('contactFormLabel9')}</label>
        <div class="mt-1">
          <textarea rows={5} onChange={handleFormInput} value={formValues[`notes-${index}`]} placeholder={t('contactFormPlaceholder9')} type="text" name={`notes-${index}`} class="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
        </div>
      </div>
    </div>
  )

}

export default RegistrationForm