import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Newsletter from 'components/layout/Newsletter';
import OurSponsors from './ticketing/pages/OurSponsors';
import useFetch from 'utils/hooks/useFetch';
import { GET_ALL_PUBLIC_POSTS } from 'api';

function Home() {
  const { data: publicPosts, fetch: getAllPublicPosts } = useFetch(GET_ALL_PUBLIC_POSTS)
  const { t } = useTranslation();

  useEffect(() => {
    getAllPublicPosts();
  }, [getAllPublicPosts])

  return (
    <div>
      <section className='hidden sm:block'>
        <div className='relative'>
          <img src="https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/f7fa7caedc885bf0359fe5a52d288406aa2dc3502d09fb6729e3204872e6fcc1aea9db0c09519361d8241e47a3a077a6d0f5cd353bc2b5627b6a7743cef9a59fccd537a9f34ce793f0cedadf3066be3f1a9fd78ee97973d418b402195dc691359c2343162444673771ec1baab06354df0e5bd2a03fad03197f29/rez-file-1721809966277.jpeg" alt="main" className='max-h-[90vh] w-full object-cover object-center' />
          {/* <div className='absolute top-0 bottom-0 left-0 right-0 text-white'>
            <div className='app-main-container flex flex-col justify-center items-start h-full gap-4 sm:gap-8'>
              <p className='text-lg sm:text-3xl md:text-4xl max-w-md font-semibold sm:font-bold app-animation-translate-start'>{t('mainBannerText')}</p>
              <a href="/about">
                <button className='text-sm sm:text-base flex items-center gap-2 app-animation-translate-start'>
                  <p>{t('learnMore')}</p>
                  <ArrowRightIcon className='h-3 sm:h-4 w-3 sm:w-4 fill-white' />
                </button>
              </a>
            </div>
          </div> */}
        </div>
      </section>

      <section className='grid grid-cols-1 sm:grid-cols-2'>
        <img src="https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/71d4c8ce466dae79e68a03cad44e00620805bbfcf1fa70dbaed4f2247e20db44f4a63f15dff3574b86c5a4c72587155469c6cec22b20f8c91c0d910a51f83e53e7fdaa81180ae44618df196e0ad5bb23c79dfc62a243a447effbe7ba36f8f209c25c0eea5aeb25fbf7254b3ae7e18eef31902ebb297fe08d54ae/rez-file-1718932237448.jpeg" alt="main" className='hidden sm:block w-full h-full aspect-video object-cover object-center app-animation-translate-right-start' />
        <img src="https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/d0610647c9f1c6b4ac2e6af6d5c7abfbacc54210d031fe49490328ccff7620f8158795df21ebb1ad810c5413ff2bb7f42ec984fb5b61e657483c1a1041e064dc66eb1eac4d51d93ab31ca0b812c8d5ae62c9f9caecd1bdac3b8400a49b635c3c916a7d0b5e909bb039270b822e118db443409d12e6c55eb5acd9/rez-file-1721810199026.jpeg" alt="main" className='sm:hidden w-full h-full object-cover object-center app-animation-translate-right-start' />
        <div className='bg-app-blue-2 text-white px-4 py-6 md:py-12 md:px-10 lg:px-20 flex items-center justify-center sm:justify-start'>
          <div >
            <p className='text-xl sm:text-2xl md:text-3xl font-semibold sm:font-bold max-w-lg app-animation-translate-start'>{t('firstHighlightText')}</p>
            <a href='/prices'>
              <button className='text-lg sm:text-xl bg-emerald-800 font-bold text-white rounded-md px-12 py-2 text-sm sm:text-base disabled:opacity-50 mt-5'>
                {t('registerNowButton')}
              </button>
            </a>
          </div>
        </div>
      </section>

      {/* <section className='bg-app-grey'>
        <div className='app-main-container space-y-8'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('eSportConsultTitle2')}
          </p>
          <div className='text-app-blue-3 text-sm sm:text-base max-w-2xl mx-auto'>{logo.generalBrands}</div>
        </div>
      </section> */}

      <OurSponsors landingPage={true} />

      {/* <section className='bg-app-grey'>
        <div className='app-main-container space-y-4'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('ourRecentEvents')}
          </p>
          <div className='grid grid-cols-2 sm:grid-cols-3 gap-4 sm:gap-8 py-4'>

            {publicPosts?.filter(post => post?.tag?.toLowerCase() === "event").slice(0, 6).map((item, i) => (
              <div key={i} className='space-y-4'>
                <img className="w-full aspect-[4/3] object-cover border" src={item.img_cover} alt={item.title} />
                <p className='font-bold text-sm sm:text-lg'>{item.title}</p>
                <p className='font-semibold text-xs sm:text-base text-app-blue-3 truncate'>{item.summary}</p>
                <a href={`article/${item?.id}`} className='flex items-center gap-2 text-xs sm:text-sm '>
                  <p>{t('learnMore')}</p>
                  <ArrowRightIcon className='h-2 w-2 sm:h-3 sm:w-3 fill-black' />
                </a>
              </div>
            ))}


          </div>
        </div>
      </section> */}

      {/* <Newsletter /> */}
    </div>
  )
}

export default Home