import React from 'react'
import { useTranslation } from 'react-i18next';

function PrivacyPolicy() {
    const { t } = useTranslation();

    const privacy_policy = [
        {
            title: t('privacyPolicyTitle1'),
            description: (
                <>
                    <p>
                        {t('privacyPolicyDesc1')}
                    </p>
                </>
            )
        },
        {
            title: t('privacyPolicyTitle2'),
            description: (
                <>
                    <p> {t('privacyPolicyDesc2')}</p>
                    <p className='font-bold'> {t('privacyPolicySubTitle21')}</p>
                    <p> {t('privacyPolicyDesc21')}</p>
                    <p className='font-bold'> {t('privacyPolicySubTitle22')}</p>
                    <p> {t('privacyPolicyDesc22')}</p>
                    <p className='font-bold'> {t('privacyPolicySubTitle23')}</p>
                    <p> {t('privacyPolicyDesc23')}</p>
                </>
            )
        },
        {
            title: t('privacyPolicyTitle3'),
            description: (
                <>
                    <p> {t('privacyPolicyDesc3')}</p>
                    <ul className="list-disc">
                        <li className="ml-4 sm:ml-6 pl-1 sm:pl-2"> {t('privacyPolicyDesc3Point1')}</li>
                        <li className="ml-4 sm:ml-6 pl-1 sm:pl-2"> {t('privacyPolicyDesc3Point2')}</li>
                        <li className="ml-4 sm:ml-6 pl-1 sm:pl-2"> {t('privacyPolicyDesc3Point3')}</li>
                        <li className="ml-4 sm:ml-6 pl-1 sm:pl-2"> {t('privacyPolicyDesc3Point4')}</li>
                        <li className="ml-4 sm:ml-6 pl-1 sm:pl-2"> {t('privacyPolicyDesc3Point5')}</li>
                    </ul>
                </>
            )
        },
        {
            title: t('privacyPolicyTitle4'),
            description: (
                <>
                    <p> {t('privacyPolicyDesc4')}</p>
                    <p className='font-bold'> {t('privacyPolicySubTitle41')}</p>
                    <p> {t('privacyPolicyDesc41')}</p>
                    <p className='font-bold'> {t('privacyPolicySubTitle42')}</p>
                    <p> {t('privacyPolicyDesc42')}</p>
                    <p className='font-bold'> {t('privacyPolicySubTitle43')}</p>
                    <p> {t('privacyPolicyDesc43')}</p>
                </>
            )
        },
        {
            title: t('privacyPolicyTitle5'),
            description: (
                <>
                    <p> {t('privacyPolicyDesc5')}</p>
                </>
            )
        },
        {
            title: t('privacyPolicyTitle6'),
            description: (
                <>
                    <p> {t('privacyPolicyDesc6')}</p>
                    <p className='font-bold'> {t('privacyPolicySubTitle61')}</p>
                    <p> {t('privacyPolicyDesc61')} </p>
                    <ul className="list-disc">
                        <li className="ml-4 sm:ml-6 pl-1 sm:pl-2"> {t('privacyPolicyDesc61Point1')}</li>
                        <li className="ml-4 sm:ml-6 pl-1 sm:pl-2"> {t('privacyPolicyDesc61Point2')}</li>
                        <li className="ml-4 sm:ml-6 pl-1 sm:pl-2"> {t('privacyPolicyDesc61Point3')}</li>
                    </ul>
                    <p className='font-bold'> {t('privacyPolicySubTitle62')}</p>
                    <p> {t('privacyPolicyDesc62')}</p>
                    <p className='font-bold'>Cloudxier - PT Cendekia Edukasi Citra Tritunggal
                    </p>
                    <p>+62-81385898240</p>
                </>
            )
        },
        {
            title: t('privacyPolicyTitle7'),
            description: (
                <>
                    <p> {t('privacyPolicyDesc7')}</p>
                </>
            )
        },
    ]

    return (
        <section>
            <div className='max-w-xl lg:max-w-6xl mx-auto px-4 py-4 sm:px-8 sm:py-8 space-y-6'>
                <div className='space-y-2 sm:space-y-4'>
                    <h1 className='text-2xl lg:text-4xl leading-normal font-bold'>{t('privacyPolicy')}</h1>
                </div>
                {privacy_policy.map((item, i) => (
                    <div className='space-y-2 sm:space-y-4'>
                        {item?.title && (
                            <p className='text-lg sm:text-xl font-bold'>{item.title}</p>
                        )}
                        {item?.description && (
                            <div className='text-justify space-y-2 sm:space-y-4 text-sm sm:text-base'>
                                {item.description}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </section>
    )
}

export default PrivacyPolicy