import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';

function Faq () {

  const { t } = useTranslation()
  
  const [ isOpenAcc, openAcc ] = useState(0)

  const faqs = [
    {
      title: t('faqTitle1'),
      description: <iframe className='aspect-video w-full' src="https://drive.google.com/file/d/11G5IyMCsfHYRkG0mY5N1JkO8yGb8LV3M/preview"></iframe>
    },
    {
      title: t('faqTitle2'),
      description: <iframe className='aspect-video w-full' src="https://drive.google.com/file/d/1FZKc6G0G-fHvvlJeKdVmfYc7hWV9k0Uk/preview"></iframe>
    },
    {
      title: t('faqTitle3'),
      description: <iframe className='aspect-video w-full' src="https://drive.google.com/file/d/1xuQducKYFbrWzKlJHRsZnipCGyFUU-x-/preview"></iframe>
    },
    {
      title: t('faqTitle4'),
      description: <iframe className='aspect-video w-full' src="https://drive.google.com/file/d/1jUroybK8tzL1uQsSo_-injHWAuIOXs-f/preview"></iframe>
    }
  ]

  return (
    <div>
      <section>
        <div className='relative'>
          <img src="https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/776595305b4c4a7a76944886f7b32fbc099f687c427e901ad2d56735a4f477a75fba20f1c0fe806201ea15d684d3dcbfb2a535ab5346fdd52a79a45c812c347b3ecfc4f62954165ca4c5022a3a16f2621eba59d1d6119e4fbeffe24a1d783a710339fa18606c97dd26bf59e4afbd41dbbcba9dcc5689ef9f2d45/file-1719757860262.jpeg" alt="main" className='w-full max-h-40 sm:max-h-60 object-cover object-center' />
          <div className='absolute top-0 bottom-0 left-0 right-0 bg-black/60 text-white'>
            <div className='app-main-container flex flex-col justify-center items-center h-full gap-4 sm:gap-8'>
              <p className='text-lg sm:text-3xl max-w-md px-8 font-semibold sm:font-bold text-center app-animation-translate-start'>{t('FAQBannerText')}</p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className='app-main-container'>
          {
            faqs && faqs.map((item, i) => {
              return (
                <div>
                  <div onClick={() => openAcc(i)} className='text-app-blue font-bold text-xl sm:text-2xl py-2 cursor-pointer select-none'>
                    {item && item.title}
                  </div>
                  {
                    isOpenAcc == i && (
                      <div className='text-sm md:text-base py-2'>
                        {item && item.description}
                      </div>
                    )
                  }
                </div>
              )
            })
          }
        </div>
      </section>
    </div>
  )

}

export default Faq