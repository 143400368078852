import axios from 'axios'
import { API_BASE_URL } from 'utils/constants/api'

const baseURL = API_BASE_URL

const api = axios.create({
  baseURL,
  withCredentials: true
})

export default api;

export const GET_ALL_PUBLIC_POSTS = {
  method: 'get',
  url: "/api/blog/v1/blogs/public"
}

export const GET_POST_DETAILS = {
  method: 'get',
  url: "/api/blog/v1/blogs/:id"
}

export const SEND_CONTACT_FORM = {
  method: "post",
  url: "/api/ecommerce/v1/contact/send"
}

export const GET_ALL_TICKETS = {
  method: 'get',
  url: "/api/ecommerce/v1/ticketing/tickets"
}

export const GET_TICKET_BY_ID = {
  method: 'get',
  url: "/api/ecommerce/v1/ticketing/tickets/:id"
}

export const CHECK_TICKET_STOCK = {
  method: "post",
  url: "/api/ecommerce/v1/ticketing/tickets/stock/:id"
}

export const GET_PRICE_CALCULATION = {
  method: "post",
  url: "/api/ecommerce/v1/ticketing/tickets/calculate/:id"
}

export const GET_ALL_PAYMENT_CHANNELS = {
  method: "get",
  url: "/api/ecommerce/v1/mpaymentchannels/all"
}

export const CREATE_BOOKING = {
  method: "post",
  url: "/api/ecommerce/v1/ticketing/booking/create"
}

export const GET_ORDER_BY_ORDER_ID = {
  method: "GET",
  url: "/api/ecommerce/v1/transactions/order/id/:order_id"
}